
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import store from '@/store/index'
import commonsApi from '@/api/services/commons'

export default {
  $inputNumberFormat: function test () {
    console.log('inputNumberFormat')
    return 'test'
  },
  /**
   *
   * @param val : 값
   * @param opt : {
   *                isMinus: 마이너스 혀옹여부 (Optional : 기본값 false)
   *                isComma: 콤마 혀옹여부 (Optional : 기본값 false)
   *                isPoint: 소수점 허용여부 (Optional : 기본값 false)
   *              }
   * @returns : {
   *            isNum: (boolean) 숫자형여부
   *            str: (string) 숫자형일 경우 : 넘어온 값, 숫자형이 아닐경우 : 빈값
   *            num: (integer | float) 숫자형일 경우 : 넘어온 값의 숫자 타입, 숫자형이 아닐경우 : 0
   *          }
   */
  getOnlyNumber (val, opt) {
    const ret = {
      isNum: false,
      str: '',
      num: 0
    }

    if (val === null || val === undefined || val === '') {
      return ret
    }

    const defaultOption = {
      isMinus: false,
      isComma: false,
      isPoint: false
    }
    const options = opt === undefined ? defaultOption : { ...defaultOption, ...opt }

    // 콤마, 소수점, 마이너스 모두 허용 불가일 경우
    if (!options.isComma && !options.isPoint && !options.isMinus) {
      const regNumber = /^[0-9]*$/
      if (regNumber.test(val)) {
        ret.isNum = true
        ret.str = val
        ret.num = parseInt(val, 10)
        return ret
      } else {
        return ret
      }
    }

    const len = val.length
    const arrNum = []
    const arrPoint = []
    let hasMinus = false // 마이너스 여부
    let hasPoint = false // 소수점 여부

    for (let i = 0; i < len; i++) {
      if (val.charAt(i) === '-') {
        if (i === 0) {
          hasMinus = true
          continue
        } else {
          return ret
        }
      } else if (val.charAt(i) === '.') {
        if (!hasPoint) {
          hasPoint = true
          continue
        } else {
          return ret
        }
      }

      if (!hasPoint) {
        if ((val.charAt(i) >= '0' && val.charAt(i) <= '9') || val.charAt(i) === ',') {
          arrNum.push(val.charAt(i))
        } else {
          return ret
        }
      } else {
        if (val.charAt(i) >= '0' && val.charAt(i) <= '9') {
          arrPoint.push(val.charAt(i))
        } else {
          return ret
        }
      }
    }

    let n = arrNum.join('')
    const p = arrPoint.join('')

    if (!options.isPoint && hasPoint) {
      return ret
    } else if (!options.isMinus && hasMinus) {
      return ret
    } else if (!options.isComma && n.includes(',')) {
      return ret
    } else if (options.isComma && n.includes(',')) {
      const arrTemp = n.split(',')
      const tempLen = arrTemp.length

      for (let i = 0; i < tempLen; i++) {
        if (arrTemp[i] === '') {
          return ret
        }
        if (i === 0 && arrTemp[i].length > 3) {
          return ret
        } else if (i > 0 && arrTemp[i].length !== 3) {
          return ret
        }
      }
      n = arrTemp.join('')
    }

    ret.isNum = true
    ret.str = val
    ret.num = hasPoint ? parseFloat(n + '.' + p, 10) : parseInt(n, 10)

    if (hasMinus) {
      ret.num *= -1
    }

    return ret
  },
  /**
   *
   * @param val : 값
   * @param opt : {
   *                isMinus: 마이너스 혀옹여부 (Optional : 기본값 false)
   *                isComma: 콤마 혀옹여부 (Optional : 기본값 false)
   *                isPoint: 소수점 허용여부 (Optional : 기본값 false)
   *              }
   * @returns : true | false
   */
  isOnlyNumber (val, opt) {
    if (val === null || val === undefined || val === '') {
      return false
    }

    const defaultOption = {
      isMinus: false,
      isComma: false,
      isPoint: false
    }
    const options = opt === undefined ? defaultOption : { ...defaultOption, ...opt }

    // 콤마, 소수점, 마이너스 모두 허용 불가일 경우
    if (!options.isComma && !options.isPoint && !options.isMinus) {
      const regNumber = /^[0-9]*$/
      if (regNumber.test(val)) {
        return true
      } else {
        return false
      }
    }

    const len = val.length
    const arrNum = []
    const arrPoint = []
    let hasMinus = false
    let hasPoint = false

    for (let i = 0; i < len; i++) {
      if (val.charAt(i) === '-') {
        if (i === 0) {
          hasMinus = true
          continue
        } else {
          return false
        }
      } else if (val.charAt(i) === '.') {
        if (!hasPoint) {
          hasPoint = true
          continue
        } else {
          return false
        }
      }

      if (!hasPoint) {
        if ((val.charAt(i) >= '0' && val.charAt(i) <= '9') || val.charAt(i) === ',') {
          arrNum.push(val.charAt(i))
        } else {
          return false
        }
      } else {
        if (val.charAt(i) >= '0' && val.charAt(i) <= '9') {
          arrPoint.push(val.charAt(i))
        } else {
          return false
        }
      }
    }

    const n = arrNum.join('')
    const p = arrPoint.join('')

    if (!options.isPoint && hasPoint) {
      return false
    } else if (!options.isMinus && hasMinus) {
      return false
    } else if (!options.isComma && n.includes(',')) {
      return false
    } else if (options.isComma && n.includes(',')) {
      const arrTemp = n.split(',')
      const tempLen = arrTemp.length

      for (let i = 0; i < tempLen; i++) {
        if (arrTemp[i] === '') {
          return false
        }
        if (i === 0 && arrTemp[i].length > 3) {
          return false
        } else if (i > 0 && arrTemp[i].length !== 3) {
          return false
        }
      }
    }
    return true
  },
  // error tooltip 모두 숨기기
  hideErrorTooltipAll (frm) {
    if (frm === null || frm === undefined) {
      return
    }
    //SYS.2024-07-17 툴팁이 한번 숨겨지면 보이지 않는 오류 관련 수정
    const elemMessage = frm.querySelectorAll('.tooltip_essen')
    //const elemMessage = frm.querySelectorAll('.tooltip_essen_box')
    const elemForm = frm.querySelectorAll('.input_error')

    let len = elemMessage.length

    for (let i = 0; i < len; i++) {
      elemMessage[i].style.display = 'none'
    }

    len = elemForm.length

    for (let i = 0; i < len; i++) {
      this.elemRemoveClass(elemForm[i], 'input_error')
    }
  },
  hasErrorTooltip (elem) {
    const $elem = $(elem)
    const $parent = $elem.parents('td, div').eq(0)
    let $msgBox = $parent.find('.tooltip_essen_box')

    return $msgBox.length > 0
  },
  showErrorTooltip (elem, message) {
    const $elem = $(elem)
    const $parent = $elem.parents('td, div').eq(0)
    let $msgBox = $parent.find('.tooltip_essen_box')

    $elem.addClass('input_error')
    $parent.addClass('position_relative')

    if ($msgBox.length === 0) {
      if (elem.id !== 'podHsCd') {
        $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
      } else { // POD HS CODE 항목인 경우 안내 메세지 풍선 도움말을 띄움
        let msgTemp = ''
        let infoMsg = 'POD HS CODE 입력 안되는 경우, 당사 고객서비스팀(CSC@EKMTC.COM / T.02-311-6114)으로 등록 요청 후 입력 바랍니다.'

        msgTemp = "<p class='tooltip_essen_box'><span class='tooltip_essen'>" + message + "&nbsp;&nbsp;<button class='tbl_icon help btn_tooltip'></button><div class='tooltip_wrap short position_absolute text_left div_tooltip' style='display: none'><div class='cont' style='padding: 10px 10px 10px 10px;'><ul class='bul_list_sm t2'><li>" + infoMsg + '</li></ul></div></div></span></p>'

        $msgBox = $(msgTemp)
      }
      $msgBox.appendTo($parent)
    }

    if (elem.id !== 'podHsCd') {
      $msgBox.find('.tooltip_essen').eq(0).text(message).show()
    } else {
      $msgBox.find('button').hover(function () {
        $msgBox.next().show()
      }, function () {
        $msgBox.next().hide()
      })

      $msgBox.find('.tooltip_essen').eq(0).show()
    }
  },
  hideErrorTooltip (elem) {
    const $elem = $(elem)
    const $parent = $elem.parents('td, div').eq(0)

    $elem.removeClass('input_error')
    $parent.find('.tooltip_essen').hide()
  },
  // error tooltip Element 노출
  showErrorTooltip_bak (elem, message) {
    this.elemAddClass(elem, 'input_error')
    const parentElem = elem.parentElement
    const nextElem = parentElem.querySelector('.tooltip_essen_box')

    if (this.isElemErrorTooltip(nextElem)) {
      nextElem.querySelector('.tooltip_essen').innerText = message
      nextElem.style.display = 'block'
    } else {
      const newElem1 = document.createElement('span')
      const newElem2 = document.createElement('span')
      newElem1.className = 'tooltip_essen_box'
      newElem2.className = 'tooltip_essen'
      newElem2.innerText = message

      if (elem.parentElement.tagName === 'TD') {
        const newElemDiv = document.createElement('div')
        newElemDiv.className = 'position_relative'

        elem.parentElement.append(newElemDiv)

        newElemDiv.append(elem)
        newElemDiv.append(newElem1)
        newElem1.append(newElem2)

        newElemDiv.parentElement.childNodes.forEach(tempEl => {
          if (tempEl !== newElemDiv) {
            newElemDiv.append(tempEl)
          }
        })
        elem.focus()
      } else {
        elem.parentElement.append(newElem1)
        newElem1.append(newElem2)
      }
    }
  },
  // error tooltip Element 숨기기
  hideErrorTooltip_bak (elem) {
    this.elemRemoveClass(elem, 'input_error')
    const parentElem = elem.parentElement
    const nextElem = parentElem.querySelector('.tooltip_essen_box')

    if (this.isElemErrorTooltip(nextElem)) {
      nextElem.style.display = 'none'
    }
  },
  // error tooltip 여부 체크
  isElemErrorTooltip (elem) {
    if (elem === null || elem === undefined) {
      return false
    }

    const tagName = elem.tagName

    if (tagName !== 'P' && tagName !== 'SPAN') {
      return false
    }

    return this.elemHasClass(elem, 'tooltip_essen_box')
  },
  // element class 유무 체크
  elemHasClass (elem, checkClassName) {
    const className = elem.className
    const arrClassName = className.split(/\s/g)
    // const len = arrClassName.length
    const chk = arrClassName.find(s => s === checkClassName)

    if (chk !== undefined) {
      return true
    } else {
      return false
    }
  },
  // element class 추가
  elemAddClass (elem, addClassName) {
    const className = elem.className
    const arrClassName = className.split(/\s/g)
    const arrNewClassName = []

    arrClassName.forEach(cls => {
      if (cls !== '' && cls !== addClassName) {
        arrNewClassName.push(cls)
      }
    })
    arrNewClassName.push(addClassName)

    elem.className = arrNewClassName.join(' ')
  },
  // element class 삭제
  elemRemoveClass (elem, removeClassName) {
    const className = elem.className
    const arrClassName = className.split(/\s/g)
    const arrNewClassName = []

    arrClassName.forEach(cls => {
      if (cls !== '' && cls !== removeClassName) {
        arrNewClassName.push(cls)
      }
    })
    elem.className = arrNewClassName.join(' ')
  },
  screenToPdf (opts) {
    store.commit('startSpinner')
    const defOpts = {
      cssSelector: 'body > div',
      fileName: 'ekmtc-pdf',
      download: 'N'
    }
    const options = { ...defOpts, ...opts }

    window.html2canvas = html2canvas //Vue.js 특성상 window 객체에 직접 할당해야한다.
    let that = this
    let pdf = new JsPDF('p', 'mm', 'a4')
    let canvas = pdf.canvas
    const pageWidth = 200 // 210 - (5 * 2) (좌우 여백)  캔버스 너비 mm
    const pageHeight = 285 // 295 - (5 * 2) 캔버스 높이 mm
    canvas.width = pageWidth

    let ele = document.querySelector(options.cssSelector)
    let width = ele.offsetWidth // 셀렉트한 요소의 px 너비
    let height = ele.offsetHeight // 셀렉트한 요소의 px 높이
    let imgHeight = pageWidth * height / width // 이미지 높이값 px to mm 변환

    if (!ele) {
      alert('출력 대상이 없습니다.')
      console.warn(options.cssSelector + ' is not exist.')
      return
    }

    $(ele).find('.screen-to-pdf-hide').hide()
    $(ele).find('.screen-to-pdf-show').show()

    return new Promise((resolve, reject) => {
      html2canvas(ele).then(async canvas => {
        let position = 0
        const imgData = canvas.toDataURL('image/png')
        let page = 1
        let pageImgHeight = pageHeight * page > imgHeight ? imgHeight : pageHeight * page
        // pdf.addImage(imgData, 'png', 5, position, pageWidth, imgHeight, undefined, 'slow')
        pdf.addImage(imgData, 'png', 5, position, pageWidth, pageImgHeight, undefined, 'slow')

        //Paging 처리
        let heightLeft = imgHeight //페이징 처리를 위해 남은 페이지 높이 세팅.
        heightLeft -= pageHeight
        while (heightLeft >= 0) {
          page++
          position = heightLeft - imgHeight
          pageImgHeight = pageHeight * page > imgHeight ? imgHeight : pageHeight * page
          pdf.addPage()
          // pdf.addImage(imgData, 'png', 5, position, pageWidth, imgHeight)
          pdf.addImage(imgData, 'png', 5, position, pageWidth, pageImgHeight)
          heightLeft -= pageHeight
        }
        if (options.download === 'Y') {
          pdf.save(options.fileName)
        }
        $(ele).find('.screen-to-pdf-hide').show()
        $(ele).find('.screen-to-pdf-show').hide()
        store.commit('endSpinner')
        resolve(pdf)
      })
        .catch(err => {
          $(ele).find('.screen-to-pdf-hide').show()
          $(ele).find('.screen-to-pdf-show').hide()
          store.commit('endSpinner')
          reject(err)
        })
    })
  },
  screenToPdfForSurcharge (opts) {
    store.commit('startSpinner')
    const defOpts = {
      cssSelector: 'body > div',
      fileName: 'ekmtc-pdf',
      download: 'N'
    }
    const options = { ...defOpts, ...opts }

    window.html2canvas = html2canvas //Vue.js 특성상 window 객체에 직접 할당해야한다.
    let that = this
    let pdf = new JsPDF('p', 'mm', 'a4')
    let canvas = pdf.canvas
    const pageWidth = 200 // 210 - (5 * 2) (좌우 여백)  캔버스 너비 mm
    const pageHeight = 285 // 295 - (5 * 2) 캔버스 높이 mm
    canvas.width = pageWidth

    let ele = document.querySelector(options.cssSelector)
    let width = ele.offsetWidth // 셀렉트한 요소의 px 너비
    let height = width * 1.414 // 셀렉트한 요소의 px 높이
    let imgHeight = pageWidth * height / width // 이미지 높이값 px to mm 변환

    if (!ele) {
      alert('출력 대상이 없습니다.')
      console.warn(options.cssSelector + ' is not exist.')
      return
    }

    $(ele).find('.screen-to-pdf-hide').hide()
    $(ele).find('.screen-to-pdf-show').show()

    return new Promise((resolve, reject) => {
      html2canvas(ele).then(async canvas => {
        let position = 5
        const imgData = canvas.toDataURL('image/png')
        let page = 1
        let pageImgHeight = pageHeight * page > imgHeight ? imgHeight : pageHeight * page

        // pdf.addImage(imgData, 'png', 5, position, pageWidth, imgHeight, undefined, 'slow')
        pdf.addImage(imgData, 'png', 5, position, pageWidth, pageImgHeight, undefined, 'slow')

        //Paging 처리
        let heightLeft = imgHeight //페이징 처리를 위해 남은 페이지 높이 세팅.

        heightLeft -= pageHeight
        while (heightLeft >= 0) {
          page++
          position = heightLeft - imgHeight
          pageImgHeight = pageHeight * page > imgHeight ? imgHeight : pageHeight * page
          pdf.addPage()
          // pdf.addImage(imgData, 'png', 5, position, pageWidth, imgHeight)
          pdf.addImage(imgData, 'png', 0, position, pageWidth, pageImgHeight)
          heightLeft -= pageHeight
        }
        if (options.download === 'Y') {
          pdf.save(options.fileName)
        }
        $(ele).find('.screen-to-pdf-hide').show()
        $(ele).find('.screen-to-pdf-show').hide()
        store.commit('endSpinner')
        resolve(pdf)
      })
        .catch(err => {
          $(ele).find('.screen-to-pdf-hide').show()
          $(ele).find('.screen-to-pdf-show').hide()
          store.commit('endSpinner')
          reject(err)
        })
    })
  },
  screenToPdfUpload (params, url) {
    /*
    try {
      const now = moment()
      const fileName = 'error-screen-' + now.format('YYYYMMDD_hhmm') + '.pdf'
      const token = sessionStorage.getItem('access_token') || ''
      const mode = process.env.VUE_APP_MODE || ''
      const agent = navigator.userAgent

      this.screenToPdf({ cssSelector: 'body' })
        .then((res) => {
          const paramData = {
            url: url || '',
            profile: mode,
            jwtToken: token,
            agent: agent,
            params: JSON.stringify(params)
          }
          const paramBlob = new Blob([JSON.stringify(paramData)], {
            type: 'application/json'
          })
          const formData = new FormData()
          formData.append('params', paramBlob)
          formData.append('files', res.output('blob'), fileName)

          commonsApi.errorLogFileUpload(formData).then((result) => {
              const fileId = result.data.fileId
              console.log('fileId :', fileId)
              // console.log('SELECT * FROM CM530I WHERE FILE_ID = ' + fileId + ';')
            })
            .catch(err => {
              console.log(err)
            })
        })
    } catch (err) {
      console.log(err)
    }
    */
  },
  screenToPrint (opts) {
    const defOpts = {
      cssSelecotr: 'body > div',
      copySelector: '#pdf_area',
      url: '#/open-popup/print-pop',
      target: 'printPop',
      popOption: 'width=1320,height=auto,menubar=no,status=status,scrollbars=yes,resizable=yes'
    }

    const options = { ...defOpts, ...opts }
    window.html2canvas = html2canvas
    let pdf = new JsPDF('p', 'mm', 'a4')
    let canvas = pdf.canvas
    const pageWidth = 200 // 210 - (5 * 2) (좌우 여백)  캔버스 너비 mm
    const pageHeight = 285 // 295 - (5 * 2) 캔버스 높이 mm
    canvas.width = pageWidth

    let ele = document.querySelector(options.cssSelector)

    if (!ele) {
      alert('출력 대상이 없습니다.')
      console.warn(options.cssSelector + ' is not exist.')
      return
    }

    return new Promise((resolve, reject) => {
      html2canvas(ele).then(async canvas => {
        const imgData = canvas.toDataURL('image/png')

        let originHtml = document.querySelector(options.copySelector).innerHTML
        let html = ''

        if (this.isNotEmpty(originHtml)) {
          html += originHtml
        }

        if (options.page === 'FreeSurcharge') {
          html += '<img src=\'' + imgData + '\' style=\'width:1080px; height:1520px\' />'
        } else {
          html += '<img src=\'' + imgData + '\' style=\'width:100%\' />'
        }

        document.querySelector(options.copySelector).innerHTML = html

        let p = window.open(
          options.url,
          'print',
          options.target,
          options.popOption
        )

        resolve(p)
      }).catch(err => {
        document.querySelector(options.copySelector).innerHTML = ''
        reject(err)
      })
    })
  },
  screenToPrintForBooking (opts) {
    const defOpts = {
      cssSelecotr: 'body > div',
      copySelector: '#pdf_area',
      url: '#/open-popup/print-pop',
      target: 'printPop',
      popOption: 'width=1320,height=auto,menubar=no,status=status,scrollbars=yes,resizable=yes'
    }

    const options = { ...defOpts, ...opts }
    window.html2canvas = html2canvas
    let pdf = new JsPDF('p', 'mm', 'a4')
    let canvas = pdf.canvas
    const pageWidth = 200 // 210 - (5 * 2) (좌우 여백)  캔버스 너비 mm
    const pageHeight = 285 // 295 - (5 * 2) 캔버스 높이 mm
    canvas.width = pageWidth

    let ele = document.querySelector(options.cssSelector)

    if (!ele) {
      alert('출력 대상이 없습니다.')
      console.warn(options.cssSelector + ' is not exist.')
      return
    }

    return new Promise((resolve, reject) => {
      let canvasOption = {
        onclone: function (document) {
          document.querySelector(options.cssSelector).className = document.querySelector(options.cssSelector).className + ' screen_shot_bk'
        }
      }
      html2canvas(ele, canvasOption).then(async canvas => {
      //html2canvas(ele).then(async canvas => {
        const imgData = canvas.toDataURL('image/png')

        let originHtml = document.querySelector(options.copySelector).innerHTML
        let html = ''

        if (this.isNotEmpty(originHtml)) {
          html += originHtml
        }
        console.log('@@@@ html1: ', html)

        html += '<img src=\'' + imgData + '\' style=\'width:100%\' />'
        document.querySelector(options.copySelector).innerHTML = html
        console.log('@@@@ html2: ', html)

        html2canvas(document.querySelector(options.copySelector)).then(async _canvas => {
          const imgData = _canvas.toDataURL('image/png')
          let html = ''
          html += '<img src=\'' + imgData + '\' style=\'width:100%\' />'
          console.log('@@@@ html: ', html)

          document.querySelector(options.copySelector).innerHTML = html
          let p = window.open(
            options.url,
            'print',
            options.target,
            options.popOption
          )

          resolve(p)
        })

        // let p = window.open(
        //   options.url,
        //   'print',
        //   options.target,
        //   options.popOption
        // )

        // resolve(p)
      }).catch(err => {
        document.querySelector(options.copySelector).innerHTML = ''
        reject(err)
      })
    })
  },
  screenToPrintPageSplit (opts) {
    const defOpts = {
      cssPageSelector: '.capture_sub_area',
      copySelector: '#pdf_area',
      url: '#/open-popup/print-pop',
      target: 'printPop',
      popOption: 'width=1320,height=auto,menubar=no,status=status,scrollbars=yes,resizable=yes'
    }

    const options = { ...defOpts, ...opts }
    window.html2canvas = html2canvas
    let pdf = new JsPDF('p', 'mm', 'a4')
    let canvas = pdf.canvas
    const pageWidth = 200 // 210 - (5 * 2) (좌우 여백)  캔버스 너비 mm
    const pageHeight = 285 // 295 - (5 * 2) 캔버스 높이 mm
    canvas.width = pageWidth

    let ele = document.querySelectorAll(options.cssPageSelector)

    if (ele.length === 0) {
      alert('출력 대상이 없습니다.')
      console.warn(options.cssSelector + ' is not exist.')
      return
    }

    return new Promise((resolve, reject) => {
      for (let i = 0; i < ele.length; i++) {
        html2canvas(ele[i]).then(async canvas => {
          const imgData = canvas.toDataURL('image/png')

          let originHtml = document.querySelector(options.copySelector).innerHTML
          let html = ''

          if (this.isNotEmpty(originHtml)) {
            html += originHtml
          }

          if (i === 0) {
            if (options.page === 'FreeSurcharge') {
              html += '<img src=\'' + imgData + '\' style=\'width:1080px; height:1520px; page-break-before: always;\' />'
            } else {
              html += '<img src=\'' + imgData + '\' style=\'width:100%; page-break-before: always;\' />'
            }
          } else {
            if (options.page === 'FreeSurcharge') {
              html += '<img src=\'' + imgData + '\' style=\'width:1080px; height:1520px; page-break-before: always; margin-top: 40px;\' />'
            } else {
              html += '<img src=\'' + imgData + '\' style=\'width:100%; page-break-before: always; margin-top: 40px;\' />'
            }
          }
          document.querySelector(options.copySelector).innerHTML = html

          if (i === (ele.length - 1)) {
            let p = window.open(
              options.url,
              'print',
              options.target,
              options.popOption
            )

            resolve(p)
          }
        }).catch(err => {
          document.querySelector(options.copySelector).innerHTML = ''
          reject(err)
        })
      }
    })
  },
  onlyNumberEvent (e) {
    var charCode = (e.which) ? e.which : e.keyCode
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      e.preventDefault()
    } else {
      return true
    }
  },
  changeUpperCase (e) {
    e.target.value = e.target.value.toUpperCase()
  },
  isEmpty (objValue) {
    if (objValue === undefined || objValue === null) {
      return true
    } else {
      return ('' + objValue).length === 0
    }
  },
  isNotEmpty (objValue) {
    return !this.isEmpty(objValue)
  },
  layerOpen (cssSelctor = '.popup_dim') {
    const st = $('html').scrollTop()

    // 팝업 후처리
    $(cssSelctor).data('scroll-top', st).fadeIn()
    $('body,html').css('overflow', 'hidden')
  },
  layerClose (cssSelctor = '.popup_dim') {
    const st = $(cssSelctor).data('scroll-top')

    $(cssSelctor).fadeOut()
    $('body,html').removeAttr('style')
    $('html').scrollTop(st)
    return false
  },
  // 이메일 형식체크
  checkEmail (val) {
    const emailRule = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // const emailRule = /^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,7}$/
    if (emailRule.test(val) === false) {
      return false
    }
    return true
  },
  multiCheckEmail (val, char) {
    const mailList = val.split(char)
    let isOk = true

    mailList.forEach(text => {
      if (!this.checkEmail(text.trim())) {
        isOk = false
      }
    })

    return isOk
  },
  // 전화번호 형식체크
  checkTel (val) {
    const telRule = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/
    if (telRule.test(val) === false) {
      return false
    }
    return true
  },
  // 텍스트 byte체크
  checkTextByte (val, byte) {
    let tcount = 0
    let tmpStr = String(val)
    let onechar = ''
    let k = ''
    const len = tmpStr.length
    for (k = 0; k < len; k++) {
      onechar = tmpStr.charAt(k)
      if (escape(onechar).length > 4) {
        tcount += 2
      } else {
        tcount += 1
      }
    }

    if (byte >= tcount) {
      return true
    } else {
      return false
    }
  },
  // 텍스트 숫자 체크
  checkTextNum (val) {
    let tcount = 0
    let tmpStr = String(val)
    let onechar = ''
    let k = ''
    const len = tmpStr.length

    for (k = 0; k < len; k++) {
      onechar = tmpStr.charAt(k)
      if (escape(onechar).length > 4) {
        tcount += 3
      } else {
        tcount += 1
      }
    }

    // console.log('checkTextByte len @@@@ ', len)
    // console.log('checkTextByte tcount @@@@ ', tcount)

    if (tcount <= 200) {
      return true
    } else {
      return false
    }
  },
  /*
    changeDatePatternEng : 날짜 표기 형식 변환
  */
  changeDatePattern (strDate, char) {
    let result = ''
    if (this.isEmpty(strDate)) {
      return ''
    }

    if (this.isEmpty(char)) {
      char = '.'
    }

    if (char !== 'KO') {
      if (strDate !== null && strDate.length >= 12) {
        result += strDate.substring(0, 4) + char + strDate.substring(4, 6) + char + strDate.substring(6, 8)
        result += ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
      } else if (strDate !== null && strDate.length >= 8) {
        result += strDate.substring(0, 4) + char + strDate.substring(4, 6) + char + strDate.substring(6, 8)
      } else if (strDate !== null && strDate.length() >= 6) {
        result += strDate.substring(0, 4) + char + strDate.substring(4, 6)
      }
    } else {
      if (strDate !== null && strDate.length >= 12) {
        result += strDate.substring(0, 4) + '년' + strDate.substring(4, 6) + '월' + strDate.substring(6, 8) + '일'
        result += ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
      } else if (strDate !== null && strDate.length >= 8) {
        result += strDate.substring(0, 4) + '년' + strDate.substring(4, 6) + '월' + strDate.substring(6, 8) + '일'
      } else if (strDate !== null && strDate.length() >= 6) {
        result += strDate.substring(0, 4) + '년' + strDate.substring(4, 6) + '월'
      }
    }

    return result
  },
  /*
    changeTimePatternEng : 시간 표기 형식 변환
  */
  changeTimePattern (str, char) {
    let result = ''
    if (this.isEmpty(str)) {
      return ''
    }

    if (this.isEmpty(char)) {
      char = ':'
    }

    if (str !== null) {
      result += str.substring(0, 2) + char + str.substring(2, 4)
    }

    return result
  },
  /*
    changeDatePatternEng : 날짜 표기 형식 변환, 영문 월 표기
  */
  changeDatePatternEng (strDate, char) {
    let result = ''
    if (this.isEmpty(strDate)) {
      return ''
    }

    if (strDate.length < 6) {
      return strDate
    }

    if (this.isEmpty(char)) {
      char = '.'
    }

    const arrTxtMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const month = Number(strDate.substring(4, 6)) - 1
    if (strDate.length >= 12) {
      result += strDate.substring(0, 4) + char + arrTxtMonth[month] + char + strDate.substring(6, 8)
      result += ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
    } else if (strDate.length >= 8) {
      result += strDate.substring(0, 4) + char + arrTxtMonth[month] + char + strDate.substring(6, 8)
    } else if (strDate.length() >= 6) {
      result += strDate.substring(0, 4) + char + arrTxtMonth[month]
    }

    return result
  },
  /*
    fnKeyupByteCheck : 입력 글자수 체크
  */
  fnKeyupByteCheck (e, limitByte) {
    const val = e.target.value
    const id = e.target.id

    const el = document.querySelector('#byte_' + id)

    if (val === undefined || val === '') {
      el.classList.add('color_black')
      el.classList.remove('color_red')
      el.innerText = '0'
      return
    }

    let tmpStr = ''
    let tmpChar = ''
    let strLength = 0

    tmpStr = String(val)
    const originLen = tmpStr.length

    for (let i = 0; i < originLen; i++) {
      tmpChar = tmpStr.charAt(i)
      if (escape(tmpChar).length > 4) {
        strLength += 3
      } else if (tmpChar !== '\r') {
        strLength++
      }
    }

    if (strLength > limitByte) {
      el.classList.add('color_red')
      el.classList.remove('color_black')
    } else {
      el.classList.add('color_black')
      el.classList.remove('color_red')
    }

    el.innerText = String(strLength).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  /*
   changeNumberFormat : 숫자 콤마 찍기
 */
  changeNumberFormat (val, opt) {
    val = '' + val

    let rtn = ''

    if (val === '') {
      return rtn
    }

    if (val === '0') {
      return '0'
    }

    const defaultOption = {
      isMinus: false,
      isComma: false,
      point: false,
      isPhone: false
    }
    const options = opt === undefined ? defaultOption : { ...defaultOption, ...opt }

    const len = val.length
    const arrNum = []
    const arrPoint = []
    let hasMinus = false // 마이너스 여부
    let hasPoint = false // 소수점 여부

    for (let i = 0; i < len; i++) {
      if (val.charAt(i) === '-') {
        if (options.isMinus && i === 0) {
          hasMinus = true
        }
        continue
      } else if (val.charAt(i) === '.') {
        if (!hasPoint) {
          hasPoint = true
        }
        continue
      }

      if (val.charAt(i) >= '0' && val.charAt(i) <= '9') {
        if (!hasPoint) {
          arrNum.push(val.charAt(i))
        } else {
          arrPoint.push(val.charAt(i))
        }
      }
    }

    let num = arrNum.join('')
    let point = arrPoint.join('')
    let isPoint = options.point !== 0 && hasPoint

    if (!options.isPhone && num !== '' && num !== '0') {
      while (num !== '0' && num.indexOf('0') === 0) {
        num = num.substring(1)
      }
    }

    if (options.point > 0 && point.length > options.point) {
      point = point.substr(0, options.point)
    }

    if (isPoint && num === '') {
      num = '0'
    }

    if (options.isComma) {
      let reg = /(^[+-]?\d+)(\d{3})/
      let ret = (num + '')

      while (reg.test(ret)) {
        ret = ret.replace(reg, '$1' + ',' + '$2')
      }

      rtn = (hasMinus ? '-' : '') + ret + (isPoint ? '.' + point : '')
    } else {
      rtn = (hasMinus ? '-' : '') + num + (isPoint ? '.' + point : '')
    }

    return rtn
  },

  getDateDayOfWeek (strDate) {
    if (this.isEmpty(strDate)) {
      return ''
    }

    const date = new Date(strDate)
    return date.getDay()
  },
  getBtnText () {
    const lang = localStorage.getItem('service_lang')
    let okTxt = 'Ok'
    let cancelTxt = 'Cancel'
    if (lang === 'KOR') {
      okTxt = '확인'
      cancelTxt = '취소'
    } else if (lang === 'JPN') {
      okTxt = 'OK'
      cancelTxt = 'CLOSE'
    }
    return { okTxt, cancelTxt }
  },
  asyncAlertMessage (options) {
    const txtObj = this.getBtnText()
    const def = {
      alertType: 'simple',
      customConfirmBtnText: txtObj.okTxt,
      customCloseBtnText: options.useConfirmBtn ? txtObj.cancelTxt : txtObj.okTxt,
      useConfirmBtn: false,
      confirmSelfYn: options.onConfirmSelf !== undefined ? 'Y' : 'N',
      closeSelfYn: options.onCloseSelf !== undefined ? 'Y' : 'N'
    }
    const obj = { ...def, ...options }

    if (obj.useConfirmBtn) {
      return new Promise(resolve => {
        obj.onConfirm = ($Simplert) => {
          if (obj.onConfirmSelf !== undefined) {
            obj.onConfirmSelf(resolve, $Simplert)
          } else {
            resolve(true)
            $Simplert.close()
          }
        }
        obj.onClose = ($Simplert) => {
          if (obj.onCloseSelf !== undefined) {
            obj.onCloseSelf(resolve, $Simplert)
          } else {
            resolve(false)
            $Simplert.close()
          }
        }
        this.alert(obj)
      })
    } else {
      return new Promise(resolve => {
        obj.onClose = ($Simplert) => {
          if (obj.onCloseSelf !== undefined) {
            obj.onCloseSelf(resolve, $Simplert)
          } else {
            resolve(true)
            $Simplert.close()
          }
        }
        this.alert(obj)
      })
    }
  },
  asyncAlert (obj) {
    console.log(obj)
    return new Promise(resolve => {
      obj.onConfirm = onConfirm
      function onConfirm () {
        resolve(true)
      }
      this.alert(obj)
    })
  },
  fnEnter (event) {
    if (event.keyCode === 13) {
      $('#e-alert-set1-btn1').click()
    }
  },
  alert (obj) {
    if (obj.isEnter === undefined) {
      obj.isEnter = false
    }
    // Enter key, Space Key로 alert 닫기
    if (obj.isEnter) {
      const main = document.querySelector('body')
      const fnKeydown = function (event) {
        if (event.keyCode === 13 || event.keyCode === 32) {
          event.preventDefault()
        }
      }
      const fnKeyup = function (event) {
        if (event.keyCode === 13 || event.keyCode === 32) {
          console.log(`obj.isEnter : ${obj.isEnter}`)
          document.querySelector('#e-alert-set1-btn1').click()
          main.removeEventListener('keydown', fnKeydown)
          main.removeEventListener('keyup', fnKeyup)
        }
      }
      main.addEventListener('keydown', fnKeydown)
      main.addEventListener('keyup', fnKeyup)
    }

    if (obj.alertType === 'simple' || obj.alertType === undefined) {
      let content = document.querySelector('.simplert__content')
      let simple = document.querySelector('#e-alert')
      let message = document.querySelector('#e-alert-message')
      let set1 = document.querySelector('#e-alert-set1')
      let set2 = document.querySelector('#e-alert-set2')
      let set3 = document.querySelector('#e-alert-set3')
      let set1btn1 = document.querySelector('#e-alert-set1-btn1')
      if (obj.useConfirmBtn === true) {
        // 기타(3번째) 버튼 사용여부에 따라 사용객체 제어
        let setbtn1 // Alert Set 객체 버튼1 (확인)
        let setbtn2 // Alert Set 객체 버튼2 (닫기)
        let setbtn3 // Alert Set 객체 버튼3 (기타)

        // 기타 버튼 사용여부에 따른 객체 설정
        if (obj.useEtcBtn === true) {
          set1.style.display = 'none'
          set2.style.display = 'none'
          set3.style.display = 'block'

          setbtn1 = document.querySelector('#e-alert-set3-btn1')
          setbtn2 = document.querySelector('#e-alert-set3-btn2')
          setbtn3 = document.querySelector('#e-alert-set3-btn3')
        } else {
          set1.style.display = 'none'
          set2.style.display = 'block'
          set3.style.display = 'none'

          setbtn1 = document.querySelector('#e-alert-set2-btn1')
          setbtn2 = document.querySelector('#e-alert-set2-btn2')
        }
        //        customCloseBtnText: '닫기',
        //         customConfirmBtnText: '확인',
        setbtn1.innerHTML = obj.customConfirmBtnText
        setbtn2.innerHTML = obj.customCloseBtnText

        // 변경후
        if (obj.onConfirm !== undefined) {
          setbtn1.onclick = () => {
            obj.onConfirm(window.app.$Simplert)
            if (obj.confirmSelfYn !== 'Y') {
              window.app.$Simplert.close()
            }
          }
        } else {
          setbtn1.onclick = () => {
            window.app.$Simplert.close()
          }
        }
        if (obj.onClose !== undefined) {
          setbtn2.onclick = function () {
            obj.onClose(window.app.$Simplert)
            if (obj.closeSelfYn !== 'Y') {
              window.app.$Simplert.close()
            }
          }
        } else {
          setbtn2.onclick = function () {
            window.app.$Simplert.close()
          }
        }

        // 기타 버튼 세팅
        if (obj.useEtcBtn === true) {
          setbtn3.innerHTML = obj.customEtcBtnText
          setbtn3.onclick = () => {
            if (obj.onEtcClick && typeof obj.onEtcClick === 'function') {
              obj.onEtcClick(window.app.$Simplert)
            }
            if (obj.closeSelfYn !== 'Y') {
              window.app.$Simplert.close()
            }
          }
        }
      } else {
        set1.style.display = 'block'
        set2.style.display = 'none'
        set3.style.display = 'none'
        set1btn1.innerHTML = obj.customCloseBtnText
        // set1btn1.onclick = obj.onClose

        if (obj.onClose !== undefined) {
          set1btn1.onclick = function () {
            obj.onClose(window.app.$Simplert)
            if (obj.closeSelfYn !== 'Y') {
              window.app.$Simplert.close()
            }
          }
        } else {
          set1btn1.onclick = function () {
            window.app.$Simplert.close()
          }
        }

        if (obj.modifyCss) {
          $('#e-alert-message').css({ overflow: 'auto', 'max-height': '225px' })
          $('#e-alert-message::-webkit-scrollbar').css({ display: 'none' })
          $('#e-alert-message').css({ '-ms-overflow-style': 'none' })
        }
      }

      message.innerHTML = obj.message.replace(/\\r/g, '').replace(/\\n/g, '<br>').replace(/\n/g, '<br>')
      simple.style.display = 'block'
      content.style.display = 'none'
    } else {
      let content = document.querySelector('.simplert__content')
      let simple = document.querySelector('#e-alert')
      content.style.display = 'block'
      simple.style.display = 'none'
    }
    window.app.$Simplert.open(obj)
    // window.app.$refs.simplert.open(obj)
  },
  isValidDate (value, maxLength) {
    if (maxLength !== 4 && maxLength !== 6 && maxLength !== 8) return false
    if (value === undefined) return false // undefined로 들어오는 경우 처리 로직이 없으면 튕기는 현상 있어 처리 로직 추가함. 2023.06.08
    if (value.length > 8) {
      value = value.substring(0, 8)
    }

    const yyyy = value.substring(0, 4)
    const mm = (maxLength >= 6) ? value.substring(4, 6) : '01'
    const dd = (maxLength === 8) ? value.substring(6, 8) : '01'

    if (isNaN(yyyy) || parseInt(yyyy) < 1000) return false
    if (isNaN(mm) || parseFloat(mm) > 12 || parseFloat(mm) < 1) return false
    if (isNaN(dd) || parseFloat(dd) < 1 || (parseFloat(dd) > this.getEndOfMonthDay(yyyy, mm))) return false

    if (Number(mm) < 1 || Number(mm) > 12) return false
    if (Number(yyyy) < 1900 || Number(yyyy) > 2050) return false

    return true
  },
  getEndOfMonthDay (yy, mm) {
    yy = parseFloat(yy)
    mm = parseFloat(mm)

    let maxDay = 0
    const maxDayInfo = {
      month1: 31,
      month3: 31,
      month4: 30,
      month5: 31,
      month6: 30,
      month7: 31,
      month8: 31,
      month9: 30,
      month10: 31,
      month11: 30,
      month12: 31
    }

    if (mm !== 2) {
      maxDay = maxDayInfo['month' + mm]
    } else {
      if (((yy % 4 === 0 && yy % 100 !== 0) || (yy % 400 === 0))) {
        maxDay = 29
      } else {
        maxDay = 28
      }
    }

    return maxDay
  },
  getDateToStrDelim (dt, delim) {
    if (this.isEmpty(delim)) {
      delim = ''
    }

    const year = dt.getFullYear()
    const month = dt.getMonth() + 1
    const date = dt.getDate()

    return year + delim + (month < 10 ? '0' + month : month) + delim + (date < 10 ? '0' + date : date)
  },
  getStrToDate (strDate) { //yyyyMMdd to date
    if (this.isEmpty(strDate)) {
      return ''
    }

    if (strDate.length < 6) {
      return ''
    } else if (strDate.length === 6) {
      strDate += strDate + '01'
    }

    const year = Number(strDate.substring(0, 4))
    const month = Number(strDate.substring(4, 6)) - 1
    const day = Number(strDate.substring(6, 8))

    let hour = 0
    let min = 0
    let seconds = 0

    if (strDate.length === 10) {
      hour = Number(strDate.substring(8, 10))
    } else if (strDate.length === 12) {
      hour = Number(strDate.substring(8, 10))
      min = Number(strDate.substring(10, 12))
    } else if (strDate.length === 14) {
      hour = Number(strDate.substring(8, 10))
      min = Number(strDate.substring(10, 12))
      seconds = Number(strDate.substring(12, 14))
    }

    const date = new Date(year, month, day, hour, min, seconds)
    return date
  },
  // 팝업 백그라운드 컬러 설정 - style binding
  // parameter
  // |_ code : 백그라운드 컬러 코드
  setPopupBgColor (code) {
    if (this.isEmpty(code)) {
      return ''
    }

    let popupbgColor = ''

    const arrPopupBgColor = [
      { code: '01', bgColor: '#FFFFFF' },
      { code: '02', bgColor: '#F3FFF7' },
      { code: '03', bgColor: '#FFFFF7' },
      { code: '04', bgColor: '#FCF7FF' },
      { code: '05', bgColor: '#F7F9FF' }
    ]

    arrPopupBgColor.forEach(element => {
      if (element.code === code) {
        popupbgColor = element.bgColor
        return false
      }
    })

    return popupbgColor
  },
  asyncAlertDefault (msg) {
    const txtObj = this.getBtnText()
    let obj = {
      alertType: 'simple',
      customCloseBtnText: txtObj.okTxt,
      message: msg
    }

    return new Promise(resolve => {
      obj.onClose = () => {
        resolve(true)
      }
      this.alert(obj)
    })
  },
  alertDefault (msg) {
    const txtObj = this.getBtnText()
    let obj = {
      alertType: 'simple',
      customCloseBtnText: txtObj.okTxt,
      message: msg
    }
    this.alert(obj)
  },
  alertCallback (msg, onClose, isEnter) {
    const txtObj = this.getBtnText()
    let obj = {
      alertType: 'simple',
      customCloseBtnText: txtObj.okTxt,
      isEnter: isEnter,
      message: msg,
      onClose: ($Simplert) => {
        onClose()
        $Simplert.close()
      }
    }
    this.alert(obj)
  },
  confirmCallBack (msg, onConfirm, onClose) {
    const txtObj = this.getBtnText()
    let obj = {
      alertType: 'simple',
      customCloseBtnText: txtObj.cancelTxt,
      customConfirmBtnText: txtObj.okTxt,
      useConfirmBtn: true,
      message: msg,
      onClose: onClose,
      onConfirm: onConfirm
    }
    this.alert(obj)
  },
  onlyEngValue (e) {
    const value = e.target.value
    e.target.value = value.replace(/[^a-zA-Z^\s]/gi, '')
  },
  onlyNumber (e) {
    const value = e.target.value
    e.target.value = value.replace(/[^0-9]/gi, '')
  },
  onlyEngNumber (e) {
    const value = e.target.value
    e.target.value = value.replace(/[^a-zA-Z0-9]/gi, '')
  },
  removeHangle (val) {
    return val.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '')
  },
  //현재 날짜 가져오기 (YYYY-MM-DD)
  getToday () {
    let today = new Date()
    let year = today.getFullYear() //연도
    let month = ('0' + (today.getMonth() + 1)).slice(-2) //월
    let date = ('0' + today.getDate()).slice(-2) //날짜

    return year + '-' + month + '-' + date
  },
  // 오늘 날짜와의 차이 비교
  getDateDiffWithToday (paramDt) {
    if (paramDt === undefined || paramDt.length < 8) {
      return ''
    }

    try {
      const today = moment()
      const dt = moment(paramDt.substring(0, 8), 'YYYYMMDD')
      const gap = today.diff(dt, 'days')

      if (isNaN(gap)) {
        //console.log('---> getDateDiffWithToday', paramDt)
        return ''
      } else if (gap < 0) {
        return 0
      } else {
        return gap + 1
      }
    } catch (e) {
      console.log(e)
      return '-1'
    }
  },
  getDefaultStEnDt (options) {
    const def = {
      startCode: 'W', // M : month, W : week, d : day (소문자)
      startGap: 0,
      endCode: 'M',
      endGap: 0
    }

    options = { ...def, ...options }

    const sdt = moment()
    const edt = moment()

    if (options.startGap > 0) {
      sdt.add(options.startGap * -1, options.startCode)
    }

    if (options.endGap > 0) {
      edt.add(options.endGap, options.endCode)
    }

    const start = sdt.format('YYYYMMDD')
    const end = edt.format('YYYYMMDD')

    return {
      stDt: start,
      enDt: end
    }
  },
  getMonthStEnDt (options) {
    const def = {
      startGap: 0,
      endGap: 0
    }
    options = { ...def, ...options }

    const sdt = moment()
    const edt = moment()

    sdt.add(options.startGap * -1, 'M')
    edt.add(options.endGap, 'M')

    const start = sdt.format('YYYYMM') + '01'
    const end = edt.format('YYYYMM') + edt.daysInMonth()

    return {
      stDt: start,
      enDt: end
    }
  },
  getAddMonth (strDate, addMonth) {
    let date = null
    if (this.isEmpty(strDate)) {
      date = new Date()
    } else {
      date = new Date(Number(strDate.substring(0, 4)), Number(strDate.substring(4, 6)) - 1, Number(strDate.substring(6, 8)))
    }

    let returnDate = new Date(date.setMonth(date.getMonth() + addMonth))

    return this.getDateToStrDelim(returnDate, '')
  },
  getAddDay (strDate, addDay) {
    let date = null

    if (this.isEmpty(strDate)) {
      date = new Date()
    } else {
      const year = strDate.substring(0, 4)
      const month = strDate.substring(4, 6)
      const day = strDate.substring(6, 8)
      const hour = strDate.length >= 10 ? strDate.substring(8, 10) : '0'
      const min = strDate.length >= 12 ? strDate.substring(10, 12) : '0'
      const sec = strDate.length >= 14 ? strDate.substring(12, 14) : '0'
      date = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(min), Number(sec))
    }

    let returnDate = new Date(date.setDate(date.getDate() + addDay))

    const year = returnDate.getFullYear()
    let month = returnDate.getMonth() + 1
    month = month < 10 ? '0' + month : month

    let day = returnDate.getDate()
    day = day < 10 ? '0' + day : day

    let hour = returnDate.getHours()
    hour = hour < 10 ? '0' + hour : hour

    let min = returnDate.getMinutes()
    min = min < 10 ? '0' + min : min

    let sec = returnDate.getSeconds()
    sec = sec < 10 ? '0' + sec : sec

    return year.toString() + month.toString() + day.toString() + hour.toString() + min.toString() + sec.toString()
  },
  getAddDayForTracking (strDate, addDay) {
    let date = null

    if (this.isEmpty(strDate)) {
      date = new Date()
    } else {
      const year = strDate.substring(0, 4)
      const month = strDate.substring(4, 6)
      const day = strDate.substring(6, 8)
      date = new Date(Number(year), Number(month) - 1, Number(day))
    }

    let returnDate = new Date(date.setDate(date.getDate() + addDay))

    const year = returnDate.getFullYear()

    let month = returnDate.getMonth() + 1
    month = month < 10 ? '0' + month : month

    let day = returnDate.getDate()
    day = day < 10 ? '0' + day : day

    let val = year.toString() + month.toString() + day.toString()

    return val
  },
  compareObject (obj1, obj2, excludeKeys) {
    const diffKeys = []

    if (obj1.constructor !== obj2.constructor) {
      diffKeys.push('ERROR_CONSTRUCTOR')
      return diffKeys
    }

    const keyLists = Object.keys(obj1)
    const len = keyLists.length
    let key

    for (let i = 0; i < len; i++) {
      key = keyLists[i]
      if (this.isDiffObject(obj1, obj2, key, excludeKeys, '')) {
        diffKeys.push(key)
      }
    }

    return diffKeys
  },
  // 변경 체크 제외 key 인지 체크
  isExcludeKeys (key, excludeKeys, parentKey) {
    const defExcludeKeys = ['fstEntUno', 'fstEntDtm', 'lstUpdUno', 'lstUpdDtm']
    const f1 = defExcludeKeys.find(k => k === key)
    if (f1) {
      return true
    }
    if (excludeKeys === null || excludeKeys === undefined) {
      return false
    }

    if (parentKey === null || parentKey === undefined) {
      parentKey = ''
    }

    const f2 = excludeKeys.find(k => {
      if (k.indexOf('.') > -1) {
        return k === parentKey + '.' + key
      } else {
        return k === key
      }
    })

    if (f2) {
      return true
    } else {
      return false
    }
  },
  isDiffObject (obj1, obj2, key, excludeKeys, parentKey) {
    if (this.isExcludeKeys(key, excludeKeys, parentKey)) {
      return false
    }

    if ((obj1[key] === null || obj1[key] === undefined || obj1[key] === '') && (obj2[key] === null || obj2[key] === undefined)) {
      return false
    }
    if ((obj1[key] !== null && obj1[key] !== undefined) && (obj2[key] === null || obj2[key] === undefined)) {
      return true
    }
    if ((obj1[key] === null || obj1[key] === undefined) && (obj2[key] !== null && obj2[key] !== undefined)) {
      return true
    }

    if (excludeKeys === null || excludeKeys === undefined) {
      excludeKeys = []
    }
    if (parentKey === null || parentKey === undefined) {
      parentKey = ''
    }

    const o1 = obj1[key]
    const o2 = obj2[key]
    const t1 = typeof o1
    const t2 = typeof o2

    if (t1 !== t2) {
      return true
    }

    if (t1 === 'object') {
      if (o1.constructor === Object && o2.constructor !== Object) {
        return true
      } else if (o1.constructor === Array && o2.constructor !== Array) {
        return true
      }

      if (o1.constructor === Object && o2.constructor === Object) {
        const keyLists = Object.keys(o1)
        const len = keyLists.length
        const tempParentKey = parentKey === '' ? key : parentKey + '.' + key
        const tempExcludeKeys = excludeKeys.filter(k => k.indexOf(tempParentKey + '.') === 0)

        for (let i = 0; i < len; i++) {
          if (this.isDiffObject(o1, o2, keyLists[i], tempExcludeKeys, tempParentKey)) {
            return true
          }
        }
      } else if (o1.constructor === Array && o2.constructor === Array) {
        const len1 = o1.length
        const len2 = o2.length

        if (len1 !== len2) {
          return true
        }

        const tempParentKey = parentKey === '' ? key : parentKey + '.' + key
        const tempExcludeKeys = excludeKeys.filter(k => k.indexOf(tempParentKey + '.') === 0)

        for (let i = 0; i < len1; i++) {
          if (o1[i].constructor === Object) {
            const keyLists = Object.keys(o1[i])
            const len = keyLists.length
            for (let j = 0; j < len; j++) {
              if (this.isDiffObject(o1[i], o2[i], keyLists[j], tempExcludeKeys, tempParentKey)) {
                return true
              }
            }
          } else {
            if (o1[i] !== o2[i]) {
              return true
            }
          }
        }
      } else if (o1 !== o2) {
        return true
      }
    } else if (o1 !== o2) {
      return true
    }
    return false
  },
  showTooltip (e) {
    const el = e.target
    const parentEl = el.closest('.span_tooltip')
    parentEl.querySelector('.tooltip_wrap').style.display = 'inline-block'
  },
  hideTooltip (e) {
    const el = e.target
    el.closest('.tooltip_wrap').style.display = 'none'
  },
  //분을 시간, 일자로 바꾸기
  makeDateForm (min) {
    let days = Math.floor(min / 60 / 24)
    let hours = Math.floor((min - (days * 60 * 24)) / 60)

    let daysStr = days
    let hourStr = (hours > 9) ? hours : '0' + hours

    return daysStr + ' Days ' + hourStr + ' Hours'
  },
  isSafari () {
    let isSafari = false
    let agent
    try {
      agent = navigator.userAgent.toLowerCase()
      if (agent.indexOf('chrome') === -1 && agent.indexOf('safari') > -1) {
        isSafari = true
      }
    } catch (e) {
      console.log(e)
    }
    return isSafari
  },
  //여러 곳에서 사용중.
  //하드 코딩 작업 반드시 나중에 DB화를 해야 될것.
  plcFnc (plcCd, type) {
    let rtnObj = {}
    if (plcCd === 'DSN') {
      const mail = type === '01' ? 'daedongagent@daum.net, daedongagent@hanmail.net' : 'daedongagent@daum.net'
      rtnObj = {
        usrNm: '대동항업',
        usrPhone: type === '01' ? '041-664-8642' : '041-664-8645',
        usrEmail: mail
      }
    } else if (plcCd === 'KPO') {
      const mail = type === '01' ? 'mail@shinjinpohang.co.kr, pohang@pohangagent.co.kr' : 'pohang@pohangagent.co.kr'
      rtnObj = {
        usrNm: '신진해운',
        usrPhone: type === '01' ? '054-272-1501 ~ 6' : '054-272-1501',
        usrEmail: mail
      }
    } else if (plcCd === 'KAN') {
      rtnObj = {
        usrNm: '여진해운',
        usrPhone: type === '01' ? '061-793-9616' : '061-793-9615',
        usrEmail: 'ops@yeojin.net'
      }
    }
    return rtnObj
  },
  // 진행중업무(수입 )
  dlyFnc (ctrCd, type) {
    let rtnObj = {}
    if (ctrCd === 'KR') {
      rtnObj = {
        usrNm: '다큐센터 발급팀',
        usrPhone: '051-797-7400 (내선6)',
        usrEmail: 'DOCIS@ekmtc.com'
      }
    }
    return rtnObj
  },
  gridExportExcel (grdObj, title, options) { // Real그리드 엑셀 다운로드
    let today = this.getToday()
    let xlsFileNm = `${title}${(today ? '_' + today.replaceAll('-', '') : '')}.xlsx` // 파일명
    let defaultOptions = { // Excel 다운로드 기본 Option
      type: 'excel',
      target: 'local',
      fileName: xlsFileNm,
      exportTemplate: true
    }
    let exportOptions = { ...defaultOptions, ...options } // Excel 다운로드 Option 조합

    if (!window.JSZip) {
      // exportGrid에서 사용할 JSZip을 선언
      import('jszip')
        .then(module => {
          window.JSZip = module.default

          grdObj.exportGrid(exportOptions) // 그리드 엑셀 다운로드
        }).catch(e => {
          console.log('>>>>>> gridExportExcel import error', e)
        })
    } else {
      grdObj.exportGrid(exportOptions) // 그리드 엑셀 다운로드
    }
  }
}
