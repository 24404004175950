import Send from '../common.client.js'

export default {
  getCommonsCodesCommodity: function (cdNm, ctrCd) {
    return new Promise((resolve, reject) => {
        Send({
            url: '/commons/codes/commodity',
            method: 'get',
            spinner: false,
            params: { cdNm: cdNm, ctrCd: ctrCd }
        }).then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
  },
  getCommonsCodesCommon: function (params) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/codes/common',
        method: 'get',
        params: { arrCdId: params.join(',') }
      }).then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCommonsCodesCountry: function (allYn, kmtcYn) {
    if (allYn === undefined) {
      allYn = 'N'
    }
    if (kmtcYn === undefined) {
      kmtcYn = 'N'
    }
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/codes/country',
        method: 'get',
        params: { allYn: allYn, kmtcYn: kmtcYn }
      }).then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCommonsCodesCustomer: function (cdNm, ctrCd, cstCatCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/codes/customer',
        method: 'get',
        spinner: false,
        params: { cdNm: cdNm, ctrCd: ctrCd, cstCatCd: cstCatCd }
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCommonsRealShipper: function (shipperNm, ctrCd, cstCatCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/codes/real-shipper',
        method: 'get',
        spinner: false,
        params: { shipperNm: shipperNm, ctrCd: ctrCd, cstCatCd: cstCatCd }
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCommonsPlaces: function (plcNm) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/places',
        method: 'get',
        spinner: false,
        params: { plcNm: plcNm }
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCommonsPlacesInfo: function (ctrCd, plcCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/places/' + ctrCd + '/' + plcCd,
        method: 'get',
        params: {}
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCommonsPlacesOfCountry: function (ctrCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/places/' + ctrCd,
        method: 'get',
        params: {}
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRegionList: function () {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/codes/regions',
        method: 'get'
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  tempFileUpload (formData) {
    return Send({
      method: 'post',
      url: '/commons/tempfile',
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  errorLogFileUpload (formData) {
    return Send({
      headers: {
        'Content-Type': 'multipart/mixed'
      },
      method: 'post',
      url: '/commons/tempfile/error-log',
      spinner: false,
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  getPlcList (areaCd) {
    return new Promise((resolve, reject) => {
      Send({
        method: 'get',
        url: '/commons/codes/plc-list',
        params: { areaCd: areaCd }
      }).then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getServerTime () {
    return Send({
      method: 'get',
      url: '/commons/codes/get-server-time'
    })
  },
  getCstInfo () {
    return new Promise((resolve, reject) => {
      Send({
        method: 'get',
        url: '/commons/codes/cst-info'
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getFactoryInfo () {
    return new Promise((resolve, reject) => {
      Send({
        method: 'get',
        url: '/commons/codes/factory-info'
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getPlcStrEnmInfo (arrPlcCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/codes/plc-enm-str-list',
        method: 'get',
        params: { arrPlcCd: arrPlcCd.join(',') }
      }).then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAgentAddr (addrStr) {
    return Send({
      url: '/commons/codes/agent-addr-list',
      method: 'get',
      params: { addrStr: addrStr }
    })
  },
  getPlcCdList (ctrCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/codes/plc-cd-list',
        method: 'get',
        params: {
          ctrCd: ctrCd
        }
      }).then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getCtrCdList (plcCd) {
    return new Promise((resolve, reject) => {
      Send({
        url: '/commons/codes/ctr-cd-list',
        method: 'get',
        params: {
          plcCd: plcCd
        }
      }).then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  sendEmail (formData) {
    return Send({
      method: 'post',
      url: '/commons/email/send',
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  getCheckTimeYn () {
    return Send({
      url: '/common/notices/check-time',
      method: 'get',
      params: {}
    })
  }
}
